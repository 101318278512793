import * as React from "react";
import Image from "next/image";
import {
  AppBar,
  Avatar,
  Button,
  CssBaseline,
  Divider,
  Toolbar,
  Typography,
  Link,
  GlobalStyles,
  Menu,
  MenuItem
} from "@mui/material";
import {useRouter} from "next/router";
import {useTranslation, Trans} from "next-i18next";
import {serverSideTranslations} from "next-i18next/serverSideTranslations";
import {deleteCookie, getCookie} from "cookies-next";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import EmailIcon from "@mui/icons-material/Email";
import {useDispatch} from "react-redux";
import {toggleModal} from "../../redux/reducers/authData";
import useEffect from "../../hooks/useEffectOne";

function HeaderContent() {
  const router = useRouter();
  const dispatch = useDispatch();

  const {t} = useTranslation("common");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const [value, setValue] = React.useState(5);

  const [name, setName] = React.useState("");
  const [selectLanguage, setSlectLanguage] = React.useState({
    icon:
      "https://upload.wikimedia.org/wikipedia/commons/8/87/US_flag_49_stars.svg",
    name: "EN"
  });

  useEffect(() => {
    getAuthenticate();
  });
  const getAuthenticate = () => {
    const user = getCookie("name");

    setName(user === undefined ? "" : user);
  };
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open2 = Boolean(anchorEl2);
  const handleClick2 = event => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleLogout = () => {
    deleteCookie("accessToken");
    deleteCookie("refreshToken");
    deleteCookie("name");
    setName("");
    router.push(`/${router.locale}/`);
  };
  const handleChangeLang = value => {
    if (value === "th") {
      setSlectLanguage({
        icon:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTs0USjfLV_GeFs4i6FEAPlpBCeG4dV2US-NA&usqp=CAU",
        name: "TH"
      });
      // router.push(`/th${router.asPath}`);
      // router.push(router.asPath, {locale: "th"});
      router.push(router.pathname, router.asPath, {locale: "th"});
      setAnchorEl2(null);
    } else if (value === "se") {
      setSlectLanguage({
        icon:
          "https://media.istockphoto.com/vectors/sweden-vector-id652785746?k=20&m=652785746&s=612x612&w=0&h=bFCDiMSf9F_Ks4V95En3Qk6dpJCBATRRuxIihvZ2ykw=",
        name: "SE"
      });
      // router.push(`/se${router.asPath}`);
      // router.push(router.asPath, {locale: "se"});
      router.push(router.pathname, router.asPath, {locale: "se"});
      setAnchorEl2(null);
    } else {
      setSlectLanguage({
        icon:
          "https://upload.wikimedia.org/wikipedia/commons/8/87/US_flag_49_stars.svg",
        name: "EN"
      });
      // router.push(`/en${router.asPath}`);
      // router.push(router.asPath, {locale: "en"});
      router.push(router.pathname, router.asPath, {locale: "en"});
      setAnchorEl2(null);
    }
  };

  const redirectAccount = () => {
    handleClose();
    router.push(`/${router.locale}/account?q=profile`);
  };
  const redirectWishlists = () => {
    handleClose();
    router.push(`/${router.locale}/wishlists`);
  };
  const redirectBookingOrder = () => {
    handleClose();
    router.push(`/${router.locale}/booking_order`);
  };
  const redirectCartOrder = () => {
    handleClose();
    router.push(`/${router.locale}/cart_order`);
  };
  const redirectInviteFriend = () => {
    try {
      if (name !== "") {
        router.push(`/${router.locale}/invite_friend`);
      } else {
        dispatch(toggleModal());
      }
    } catch (e) {
      console.log("err", e);
    }
  };
  return (
    <React.Fragment>
      {/* Header unit */}
      <GlobalStyles styles={{ul: {margin: 0, padding: 0, listStyle: "none"}}} />
      <CssBaseline />
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{borderBottom: theme => `1px solid ${theme.palette.divider}`}}
      >
        <Toolbar sx={{flexWrap: "wrap"}}>
          <Link underline="none" href="/" noWrap sx={{flexGrow: 1}}>
            <Typography style={{fontSize: "30px", color: "#3e5a50"}}>
              booclii
            </Typography>
          </Link>
          <nav>
            <Link
              underline="none"
              variant="button"
              href="#"
              onClick={() => redirectInviteFriend()}
            >
              <EmailIcon style={{paddingTop: 12, color: "#000000"}} />
              <span style={{marginLeft: "10px", color: "#000000"}}>
                {t("headerButtons.button3")}
              </span>
            </Link>
          </nav>
          {name === "" ? (
            <nav>
              <Link
                underline="none"
                variant="button"
                color="text.primary"
                href={`${router.locale}/login`}
                sx={{my: 1, mx: 1.5}}
              >
                <AccountCircleOutlinedIcon
                  style={{paddingTop: 12, color: "#000000"}}
                />
                {t("headerButtons.button2")}
              </Link>
            </nav>
          ) : (
            <div>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                style={{paddingTop: 12}}
              >
                <AccountCircleOutlinedIcon style={{color: "black"}} />
                <span style={{marginLeft: "10px", color: "#000000"}}>
                  {name}
                </span>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button"
                }}
              >
                <MenuItem onClick={redirectWishlists}>
                  <FavoriteBorderOutlinedIcon style={{marginRight: 10}} />
                  <span style={{marginLeft: "10px", color: "#000000"}}>
                    Wishlists
                  </span>
                </MenuItem>
                <Divider />
                <MenuItem onClick={redirectBookingOrder}>
                  <CalendarTodayOutlinedIcon style={{marginRight: 10}} />
                  <span style={{marginLeft: "10px", color: "#000000"}}>
                    Booking Order
                  </span>
                </MenuItem>
                <Divider />
                <MenuItem onClick={redirectCartOrder}>
                  <ShoppingCartOutlinedIcon style={{marginRight: 10}} />
                  <span style={{marginLeft: "10px", color: "#000000"}}>
                    Cart Order
                  </span>
                </MenuItem>
                <Divider />
                <MenuItem onClick={redirectAccount}>
                  <AccountCircleOutlinedIcon style={{marginRight: 10}} />
                  <span style={{marginLeft: "10px", color: "#000000"}}>
                    Account
                  </span>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClose}>
                  <AutorenewOutlinedIcon style={{marginRight: 10}} />
                  <span style={{marginLeft: "10px", color: "#000000"}}>
                    Switch to Clinic
                  </span>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout}>
                  <LogoutOutlinedIcon style={{marginRight: 10}} />
                  <span style={{marginLeft: "10px", color: "#000000"}}>
                    Logout
                  </span>
                </MenuItem>
              </Menu>
            </div>
          )}
          <Button
            id="basic-button"
            aria-controls={open2 ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open2 ? "true" : undefined}
            onClick={handleClick2}
            style={{paddingTop: 12}}
          >
            <span style={{marginLeft: "10px"}}>{router.locale}</span>

            <KeyboardArrowDownIcon />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl2}
            open={open2}
            onClose={handleClose2}
            MenuListProps={{
              "aria-labelledby": "basic-button"
            }}
          >
            <MenuItem onClick={() => handleChangeLang("en")}>
              <Avatar
                alt="usa"
                src={
                  "https://upload.wikimedia.org/wikipedia/commons/8/87/US_flag_49_stars.svg"
                }
                sx={{width: 24, height: 24}}
              />
              <span style={{marginLeft: "10px"}}>EN</span>
            </MenuItem>
            <MenuItem onClick={() => handleChangeLang("th")}>
              <Avatar
                alt="thai"
                src={
                  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTs0USjfLV_GeFs4i6FEAPlpBCeG4dV2US-NA&usqp=CAU"
                }
                sx={{width: 24, height: 24}}
              />

              <span style={{marginLeft: "10px"}}>TH</span>
            </MenuItem>
            <MenuItem onClick={() => handleChangeLang("se")}>
              <Avatar
                alt="sweden"
                src={
                  "https://media.istockphoto.com/vectors/sweden-vector-id652785746?k=20&m=652785746&s=612x612&w=0&h=bFCDiMSf9F_Ks4V95En3Qk6dpJCBATRRuxIihvZ2ykw="
                }
                sx={{width: 24, height: 24}}
              />

              <span style={{marginLeft: "10px"}}>SE</span>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      {/* Body unit */}

      {/* Footer */}

      {/* End footer */}
    </React.Fragment>
  );
}
export const getStaticProps = async ({locale}) => ({
  props: {
    ...(await serverSideTranslations(locale, ["common"]))
  }
});
export default function Header() {
  return <HeaderContent />;
}
